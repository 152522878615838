import { Suspect } from "../../types/games";
import './SuspectCards.css';

interface ISuspectProps {
    Suspects: Suspect[];
}

export default function SuspectCards(props: ISuspectProps) {
  return (
    <div>
        <h1>Suspects</h1>
        <div className="suspect-cards-container">
        {props.Suspects.map((suspect, index) => (
            <div className="suspect-card" key={index}>
            <h3>{suspect.name}</h3>
            <div className="suspect-image-placeholder">Placeholder</div>
            <div className="suspect-description">{suspect.description}</div>
            </div>
        ))}
        </div>
    </div>
  );
}

