import logo from '../images/Whodunnit.png';
import '../App.css';

export default function Home() {
    return (
        <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            The Whodunnit Club
          </p>
        </header>
      </div>
    );
    }