// LocationCards.tsx
import React from 'react';
import { GameLocation } from '../../types/games';
import './LocationCards.css';

interface ILocationProps {
    Locations: GameLocation[];
}

export default function LocationCards(props: ILocationProps) {
  return (
    <div className="location-cards-container">
      {props.Locations.map((location, index) => (
        <div className="location-card" key={index}>
          <img src={location.image} alt={location.name} className="location-image" />
          <div className="location-info">
            <h3>{location.name}</h3>
            <h5>{location.subLocation}</h5>
          </div>
          <div className="location-description">{location.description}</div>
        </div>
      ))}
    </div>
  );
}

