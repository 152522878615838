import './nye2024.css';

export default function NYE2024() {
    return (
        <div>
            <header className="header">
                <h1>Murder: It was acceptable in the 80s</h1>
            </header>
            <div className="container">
                <div className="left-column">
                    <video controls autoPlay className="responsive-video">
                        <source src={require('./NYE2024.mp4')} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="right-column">
                    <h2>The Twisted Tree: New Years Eve 2024</h2>
                    <p>Fancy something different this New Years Eve?</p>
                    <p>Join 'Our Graham' at The Twisted Tree in Castleford as we enjoy a romp in the 1980s.</p>
                    <p>This 80s murder mystery is filled with nostalgia, a packed 80s soundtrack, retro vibes, and big shoulder pads.</p>
                    <p>A three course meal is included alongside a fun evening leading up to the celebrations at midnight.</p>
                    <p><b>Characters Include:</b></p>
                    <ul className="character-list">
                        <li>Margaret Thatcher</li>
                        <li>Princess Diana</li>
                        <li>Boy George</li>
                        <li>Gary Lineker</li>
                        <li>Nigel Mansell</li>
                        <li>Adam Ant</li>
                        <li>Cilla Black</li>
                        <li>Freddie Mercury</li>
                        <li>Kate Bush</li>
                        <li>Arthur Scargill</li>
                        <li>Madonna</li>
                        <li>and many more!</li>
                    </ul>
                    <p>We hope to see you there! Book early to avoid disappointment.</p>
                    <p>
                        <a href="https://www.thetwistedtree.menu/" target="_blank" rel="noopener noreferrer">
                            Visit The Twisted Tree website for more details.
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};