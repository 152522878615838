interface ITitleDescriptionProps {
    title: string;
    description: string;
}

export default function TitleDescription(props: ITitleDescriptionProps) {
    return (
        <div>
            <h1>{props.title}</h1>
            <p>{props.description}</p>
        </div>
    );
}