import LocationCards from "../components/LocationsCards/LocationCards"
import SuspectCards from "../components/SuspectCards/SuspectCards"
import TitleDescription from "../components/TitleDescription/TitleDescription"
import { GameLocation, Suspect } from "../types/games"

export default function GameOne() {
    const title = "Murder in Hollywood Hills"
    const description = "Officer Alfred Feel is the first on sceene at a murder in the Hollywood Hills, can you help Alfred solve the case before the the dectectives turn up and take over?"
    const suspects: Suspect[] = [
        {
          name: "The Butler",
          description: "A loyal servant with years of service but recently found in financial trouble.",
        },
        {
          name: "The Estranged Relative",
          description: "Recently returned from abroad with unclear intentions and a disputed inheritance.",
        },
        {
          name: "The Business Partner",
          description: "A long-time associate with a known rivalry and several heated arguments over business decisions.",
        }
    ]

    const locations: GameLocation[] = [
        {
          name: "Manor House",
          subLocation: "Indoors",
          description: "The main hall of the manor, filled with antique furniture and a looming sense of mystery.",
          image: "/path/to/manor-house.jpg", // Example path, adjust accordingly
        },
        {
          name: "Garden",
          subLocation: "Outdoors",
          description: "A serene garden with a maze of hedges, hiding secrets in its shadows.",
          image: "/path/to/garden.jpg",
        }
    ]

    return (
        <div>
            <div>
                <TitleDescription title={title} description={description} />
                {/* display the suspects */}
                <SuspectCards Suspects={suspects} />
                <LocationCards Locations={locations} />
            </div>
        </div>
    )
}