// Import necessary components from react-router-dom
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';

// Import your page components
import HomePage from './pages/home';
import Test from './pages/test';
import GameOne from './pages/gameone';
import NYE2024 from './pages/2024/TwistedTree/NYE2024';
import Terminal from './pages/Terminal/Terminal';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Define your routes within Routes component */}
        <Routes>
          <Route path="/" element={<HomePage />} /> 
          <Route path="/test" element={<Test />} />
          <Route path="/gameone" element={<GameOne/>} />
          <Route path="/2024/TwistedTree/NYE2024" element={<NYE2024 />} />
          {/* <Route path="/dailup" element={<NYE2024 />} /> */}
          <Route path="/terminal" element={<Terminal />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
